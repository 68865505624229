<script>
import {getMonthPremium} from "@/services/api/requests";
import {dictMonths} from "@/services/mappers";
import NewMonth from "@/components/NewMonth.vue";
import {mapGetters} from "vuex";
import CardMonth from "@/components/CardMonth.vue";
import {mdiTimerSandFull} from "@mdi/js";
export default {
  name: "Start",
  components: {CardMonth, NewMonth},
  data: function () {
    return {
      mdiTimerSandFull,
      dictMonths,
      loadingMonths: false,
      disableButtons: false,
      reports: [],
    }
  },
  computed: {
    ...mapGetters("User", {
      isAdmin1: 'isAdmin1',
      isManager1: 'isManager1',
      isHeadOfDepartment: 'isHeadOfDepartment',
    }),
    showCreate() {
      return this.isAdmin1
    },
  },
  async created() {
    this.loadingMonths = true
    await this.getReports()
    this.loadingMonths = false
  },
  methods: {
    async getReports() {
      let reports = await getMonthPremium()
      reports.forEach(report => {
        report.title = `${this.dictMonths[report.month - 1].text} ${report.year}`
      })
      reports = reports.reverse()
      // reformat the reports to following structure: [{year: 2022, reports: [...] }, ...]
      const reportsByYear = []
      reports.forEach(report => {
        const year = report.year
        const index = reportsByYear.findIndex(item => item.year === year)
        if (index >= 0) {
          reportsByYear[index].reports.push(report)
        } else {
          reportsByYear.push({year, reports: [report]})
        }
      })
      this.reports = reportsByYear
    },
  },
}
</script>

<template>
  <v-container>
    <div
      v-if="loadingMonths"
      class="cards"
    >
      <v-skeleton-loader
        v-for="i in 12"
        :key="i"
        type="image"
        class="mx-auto skeleton-loader"
        width="100%"
        :height="334"
      />
    </div>
    <template v-else>
      <NewMonth
        v-if="showCreate"
        v-model="disableButtons"
        @submitted="getReports"
      />
      <template v-if="reports.length === 0 && !isAdmin1">
        <p
          class="text-large text-center font-weight-bold mt-7 mb-3"
          style="color: #666666"
        >
          <v-icon
            size="40"
            class="mb-5"
            color="#666666"
          >
            {{ mdiTimerSandFull }}
          </v-icon>
          <br>
          Данные по премированию сотрудников отсутствуют.
          Отдел HR работает над их подготовкой. Пожалуйста, зайдите позже.
        </p>
      </template>
      <template v-for="(items, index) in reports">
        <h2
          :key="items.year"
          class="text-h4 font-weight-black mt-7 mb-3"
          style="color: #666666"
        >
          {{ items.year }}
        </h2>
        <div
          :key="`${index}_container`"
          class="cards"
        >
          <CardMonth
            v-for="report in items.reports"
            :key="`${index}_${report.title}`"
            :report="report"
            :disable-buttons="disableButtons"
          />
        </div>
      </template>
    </template>
  </v-container>
</template>

<style scoped lang="scss">
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  &::v-deep {
    .v-card__title {
      word-break: break-word !important;
    }
  }
}
.skeleton-loader::v-deep {
  .v-skeleton-loader__image {
    height: 100% !important;
  }
}
</style>