<script>
import Status from "@/components/Status.vue";
import {statusKeys} from "@/services/mappers";
import {getFileForAccountant} from "@/services/api/requests";
import {mdiArrowRight, mdiLock, mdiCalendarClock} from "@mdi/js";
import {mapGetters} from "vuex";
import LabelDeadline from "@/components/LabelDeadline.vue";

export default {
  name: "CardMonth",
  components: { LabelDeadline, Status },
  props: {
    report: {
      type: Object,
      required: true,
    },
    disableButtons: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      mdiLock,
      mdiArrowRight,
      mdiCalendarClock,
      downloadingReport: false,
    }
  },
  computed: {
    ...mapGetters("User", {
      isAdmin1: 'isAdmin1',
      isManager1: 'isManager1',
      isHeadOfDepartment: 'isHeadOfDepartment',
    }),
    showCreate() {
      return this.isAdmin1
    },
    showToTableBtn() {
      return this.isManager1
    },
    showToDepartmentsBtn() {
      return this.isAdmin1
    },
    showToDepartmentBtn() {
      return this.isHeadOfDepartment
    },
    showDownloadFirstReportBtn() {
      return this.isAdmin1
    },
    showDeadline() {
      return this.report?.deadline
    },
  },
  methods: {
    isReportLocked(status) {
      return [statusKeys.closed, statusKeys.signed].includes(status)
    },
    async downloadReport(year, month) {
      this.downloadingReport = true
      const link = await getFileForAccountant(year, month);
      link.download = `Отчет_по_премиям_${this.report.title}.xlsx`;
      document.body.appendChild(link);
      link.click();
      setTimeout(()=> this.downloadingReport = false, 100)
    },
    goToFormular(formularId) {
      this.$router.push({name:'Formular', params: {formularId}})
    },
    goToDepartments(year, month) {
      this.$router.push({name:"Departments", query: {year: year, month: month}})
    },
    goToDepartment(year, month) {
      this.$router.push({name:"Department", query: {year: year, month: month}})
    },
  }
}
</script>

<template>
  <v-card
    class="mx-auto d-flex flex-column"
    width="100%"
    height="100%"
  >
    <v-card-title class="pb-2 d-flex justify-space-between">
      <span>{{ report.title }}</span>
      <v-icon v-if="isReportLocked(report.status)">
        {{ mdiLock }}
      </v-icon>
      <LabelDeadline
        v-else-if="showDeadline"
        :deadline="report.deadline"
        small
      />
    </v-card-title>
    <v-card-text
      v-if="report.status"
      class="flex-grow-1"
    >
      <div>
        <Status
          :status="report.status"
        />
      </div>
      <div
        v-if="showDownloadFirstReportBtn"
        class="pt-2"
      >
        <span class="mr-2">Скачать бухгалтерский отчет:</span>
        <v-btn
          color="primary"
          small
          depressed
          :disabled="disableButtons"
          :loading="downloadingReport"
          @click="downloadReport(report.year, report.month)"
        >
          скачать
        </v-btn>
      </div>
    </v-card-text>
    <v-row class="ma-0 flex-grow-0">
      <v-col cols="12">
        <v-btn
          v-if="showToTableBtn"
          color="primary"
          block
          depressed
          :disabled="disableButtons"
          @click="goToFormular(report.formular)"
        >
          <span>к формуляру</span>
          <v-icon
            small
            class="mt-n1 ml-2"
          >
            {{ mdiArrowRight }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showToDepartmentsBtn"
          color="primary"
          block
          depressed
          class="my-1 mx-0"
          :disabled="disableButtons"
          @click="goToDepartments(report.year, report.month)"
        >
          <span>к подразделениям</span>
          <v-icon
            small
            class="mt-n1 ml-2"
          >
            {{ mdiArrowRight }}
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showToDepartmentBtn"
          color="primary"
          block
          depressed
          class="my-1 mx-0"
          :disabled="disableButtons"
          @click="goToDepartment(report.year, report.month)"
        >
          <span>к подразделению</span>
          <v-icon
            small
            class="mt-n1 ml-2"
          >
            {{ mdiArrowRight }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>

</style>