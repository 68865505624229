<script>
import {dictMonths} from "@/services/mappers";
import {mdiCalendar, mdiCalendarClock, mdiFileExcel} from "@mdi/js";
import {updateData} from "@/services/api/requests";
import store from "@/store";

export default {
  name: "NewMonth",
  props: {
    value: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      month: null,
      year: null,
      file: null,
      deadline: null,
      deadlineModal: false,
      dictMonths,
      mdiFileExcel,
      mdiCalendarClock,
      mdiCalendar,
      rulesMonth: [
        (v) => !!v || "Необходимое поле"
      ],
      rulesYear: [
        (v) => !!v || "Необходимое поле"
      ],
      rulesExcelFile: [
        (v) => !!v || "Необходимое поле"
      ],
      rulesDeadline: [
        (v) => !!v || "Необходимое поле"
      ],
    };
  },
  async created() {
    this.setCurrentMonthAndYear()
  },
  methods: {
    log() {
      console.log(this.deadline)
    },
    reset() {
      this.setCurrentMonthAndYear()
      this.file = null
      this.deadline = null
    },
    getYears() {
      const currentYear = new Date().getFullYear();
      const years = [];
      for (let i = 0; i < 5; i++) {
        years.push(currentYear - i);
      }
      return years;
    },
    async submit() {
      this.$emit("input", true )
      try{
        const formData = new FormData();
        formData.append("year", this.year);
        formData.append("month", this.month);
        formData.append("file", this.file);
        formData.append("deadline", this.deadline);
        const res = await updateData(formData)
        if (res) {
          this.$emit("submitted")
          this.reset()
          await store.dispatch('App/setSnackbar', {  message: "Новый месяц успешно добавлен", type: 'success' });
          setTimeout(async () => {
            await store.dispatch('App/setSnackbar', false)
          }, 5000)
          this.$refs.newMonthForm.resetValidation()
        }
      } catch (e) {
        console.log(e)
      }
      this.$emit("input", false )
    },
    setCurrentMonthAndYear() {
      const date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
    },
  }
}
</script>

<template>
  <v-card max-width="700">
    <v-card-title class="pb-2">
      Новый месяц
    </v-card-title>
    <v-card-text>
      <v-form ref="newMonthForm">
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="month"
              label="Месяц"
              required
              :disabled="value"
              :items="dictMonths"
              :rules="rulesMonth"
              :prepend-icon="mdiCalendar"
            />
            <v-select
              v-model="year"
              label="Год"
              :disabled="value"
              required
              :rules="rulesYear"
              :items="getYears()"
              :prepend-icon="mdiCalendar"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-file-input
              v-model="file"
              label="Файл"
              :disabled="value"
              :rules="rulesExcelFile"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              :prepend-icon="mdiFileExcel"
              required
            />
            <v-dialog
              ref="dialog"
              v-model="deadlineModal"
              persistent
              :return-value.sync="deadline"
              width="290px"
            >
              <template #activator="{on, attrs}">
                <v-text-field
                  v-model="deadline"
                  :prepend-icon="mdiCalendarClock"
                  label="Дедлайн для менеджеров"
                  readonly
                  :disabled="value"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="deadline"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="deadlineModal = false"
                >
                  Отменить
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(deadline)"
                >
                  Сохранить
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        :loading="value"
        color="primary"
        block
        :disabled="!file || !month || !year || !deadline"
        @click="submit"
      >
        Отправить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>